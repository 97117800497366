<template>
    <v-main>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <v-container class="fill-height" blue fluid style="height: 100vh;">
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="4">
                    <v-card class="elevation-12">
                        <div class="text-center">
                            <img src="../assets/logo.jpg" height="100px" alt="" class="mt-5">
                        </div>
                        <v-card-text>
                            <h1 class="text-center blue--text text--accent-3">Merchant Portal</h1>
                            <v-form class="mt-5" ref="mainForm">
                          <v-text-field
                          v-model="form.user_login_id"
                            label="User Id"
                            name="user_login_id"
                            prepend-icon="mdi-account"
                            type="text"
                            color="blue accent-3"
                            :rules="inputRules"
                          />

                          <v-text-field
                            v-model="form.user_password"
                            id="password"
                            label="Password"
                            name="user_password"
                            prepend-icon="mdi-lock"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            color="blue accent-3"
                             @click:append="showPassword = !showPassword"
                             :rules="inputRules"
                          />
                        </v-form>
                        </v-card-text>
                        <div class="text-center mt-5">
                            <v-btn rounded color="blue accent-3" dark class="mb-3" @click="flogin">LOGIN</v-btn>
                        </div>
                    </v-card>
                </v-col>

            </v-row>
        </v-container>
    </v-main>
</template>

<script>
//import axios from 'axios';
import DlgMessage from '@/components/DlgMessage'
import MpUserService from "@/services/MpUserService"
export default {
    components:{DlgMessage,},
    data(){
        return {
            showPassword:false,
            form:{
                user_login_id:'user1',
                user_password:'password',
                platform:'merchantportal'
            },
            inputRules: [
             v => !!v || 'This field is required'
            ],
        }
    },
        methods:{
                
        async flogin(){
            if(this.$refs.mainForm.validate()) {
                MpUserService.login(this.form).then((res) => {
                    //console.log(res.data.token_auth);
                    localStorage.setItem('mtoken_auth',res.data.token_auth);
                    localStorage.setItem('mloginData',res.data.data);
                    localStorage.setItem('user_login_id',res.data.data.user_login_id);
                    let loginTmp = res.data.data;
                    if(loginTmp.user_login_id){
                        //this.$store.dispatch('loginState',true);
                        this.$store.dispatch('aLogin');
                    }
                    //console.log("local storage",localStorage.getItem('mtoken_auth'));
                        
                    this.$router.push('/dashboard')
                    
                     
                }).catch((e)=>{
                    //console.log(e.response.data);
                    this.$refs.dlgMsg.showDlgBox(true,'error','Login',e.response.data['error_message']);
                })
                ;


                // try {
                //     const res = await axios.post('ap_admin_user/login', this.form)
                //     localStorage.setItem('token_auth',res.data.token_auth);
                //     localStorage.setItem('loginData',res.data.data);
                //     this.$store.dispatch('loginData',res.data.data);
                //     this.$router.push('/dashboard')

                // } catch(e) {
                //     console.error(e.response.status);
                //     console.error(e.response.data.error_message);
                //     this.error_message = e.response.data.error_message;
                //     this.error_code = e.response.status;
                //     this.$refs.dlgMsg.showDlgBox(true,'error','Login',e.response.data.error_message);
                // }
                
            }            

        },
        dlgClose(){
            this.visible = false;
        },
    }
}
</script>
