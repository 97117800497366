import axios from "axios"
// localStorage.setItem('login_id',"anthon@aim-net.com.my");
let vlogin_id = localStorage.getItem('user_login_id');
class ApAdminUserService {

    login(form) {
        //console.log("local storage",localStorage.getItem('login_id'));
        //let form = {login_id:};
        //console.log(form);
        return axios.post("mp_user/login", form);

    }

    change_password(form) {
        form.user_login_id = vlogin_id;
        //console.log(vlogin_id);
        return axios.post("mp_user/change_own_password", form);

    }

    view_profile(form) {
        form.user_login_id = vlogin_id;
        //console.log(vlogin_id);
        return axios.post("mp_user/view_profile", form);

    }

    update_own_profile(form) {
        form.user_login_id = vlogin_id;
        //console.log(vlogin_id);
        return axios.post("mp_user/update_profile", form);

    }
    

    

    
  
}

export default new ApAdminUserService();